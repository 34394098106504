<template>
  <div>
    <el-card>
      <div class="header">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="账号">
            <el-input
              v-model="queryForm.username"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input
              v-model="queryForm.nickname"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="queryForm.phone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button size="small" type="primary" @click="listToExcel"
            >导出
          </el-button>
          <!--          <el-button size="small" type="danger">删除</el-button>-->
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column property="username" label="系统账号" />
          <el-table-column property="nickname" label="用户昵称" />
          <el-table-column property="ip" label="IP地址" />
          <el-table-column
            property="device"
            :show-overflow-tooltip="true"
            label="设备"
          />
          <el-table-column property="os" label="系统" />
          <el-table-column property="browser" label="浏览器" />
          <el-table-column property="loginType" label="操作类型">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.loginType == 0"
                >登录成功
              </el-tag>
              <el-tag type="danger" v-if="scope.row.loginType == 1"
                >登录失败
              </el-tag>
              <el-tag type="info" v-if="scope.row.loginType == 2"
                >退出登录
              </el-tag>
              <el-tag type="warning" v-if="scope.row.loginType == 3"
                >续签token
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column property="remark" label="备注" />
          <el-table-column
            property="createTime"
            sortable="custom"
            label="操作时间"
          />
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getSysLoginRecordPage } from '@/api/sys-login-record'
import { LOGIN_RECORD } from './Export2ExcelConstants'

const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  username: '',
  phone: '',
  nickname: ''
})

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getSysLoginRecordPage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 根据创建时间排序
 */
const handleSortChange = val => {
  console.log(val.order)
  if (val.order === 'ascending') {
    queryForm.value.order = 'asc'
  } else {
    queryForm.value.order = 'desc'
  }
  getListData()
}

/**
 * 导出为excel
 */
const listToExcel = async () => {
  // 导入工具包
  const excel = await import('@/utils/Export2Excel')
  const data = formatJson(LOGIN_RECORD, tableData.value)
  excel.export_json_to_excel({
    // excel 表头
    header: Object.keys(LOGIN_RECORD),
    // excel 数据（二维数组结构）
    data,
    // 文件名称
    filename: '登录日志',
    // 是否自动列宽
    autoWidth: true,
    // 文件类型
    bookType: 'xlsx'
  })
}

// 该方法负责将数组转化成二维数组
const formatJson = (headers, rows) => {
  // 首先遍历数组
  // [{ username: '张三'},{},{}]  => [[’张三'],[],[]]
  return rows.map(item => {
    return Object.keys(headers).map(key => {
      // 操作类型处理
      if (headers[key] === 'loginType') {
        switch (item[headers[key]]) {
          case 0:
            return '登录成功'
          case 1:
            return '登录失败'
          case 2:
            return '退出登录'
          case 3:
            return '续签token'
        }
      }
      return item[headers[key]]
    })
  })
}
</script>

<style scoped></style>
