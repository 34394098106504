import request from '../utils/request'

/**
 * 获取登录日志分页数据
 * @param data
 * @returns {AxiosPromise}
 */
export const getSysLoginRecordPage = data => {
  return request({
    url: '/loginRecord/page',
    params: data
  })
}
