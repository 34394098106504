/**
 * 导入数据对应表
 */
export const LOGIN_RECORD = {
  系统账号: 'username',
  用户昵称: 'nickname',
  IP地址: 'ip',
  设备: 'device',
  系统: 'os',
  浏览器: 'browser',
  操作类型: 'loginType',
  备注: 'remark',
  时间: 'createTime'
}
